import React from 'react'
import cross from '../../static/img/cross.svg'
import rightArrow from '../../static/img/right-arrow.svg'

const ModalCarConfirm = ({close, approve}) => {

    return (
        <div onClick={() => close()}
             className="modal__wrapper modal__wrapper-photos modal-active">
            <div className="modal modal-photos" onClick={(e) => e.stopPropagation()}>
                <div className="modal__close" onClick={() => close()}>
                    <img src={cross} alt="cancel" width="16px" height="16px" />
                </div>
                <div className="modal__title visually-hidden"><span>Подтверждение данных</span></div>
                <div className="modal__body">
                    <p className="modal__text">
                        Подтверждаете корректность введенных данных?
                    </p>
                    <button className="modal__button btn" onClick={() => approve()}>
                        Подтверждаю
                        <img className="btn__img" src={rightArrow} alt="" width="10px" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalCarConfirm
