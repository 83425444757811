import React  from 'react'
import Header from './parts/Header'
import Footer from './parts/Footer'

const Main = ({ children, header }) => (
  <>
      <header>
        <Header header={header} />
      </header>
      {children}
      <Footer />
  </>
)

export default Main
