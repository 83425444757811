import React from 'react'
import cross from '../../static/img/cross.svg'

const ModalCarInfo = ({close}) => {
    return (
        <div onClick={() => close()}
             className="modal__wrapper modal__wrapper-photos modal-active">
            <div className="modal modal-photos" onClick={(e) => e.stopPropagation()}>
                <div className="modal__close" onClick={() => close()}>
                    <img src={cross} alt="cancel" width="16px" height="16px"  />
                </div>
                <div className="modal__title visually-hidden"><span>Пример фотографий</span></div>
                <div className="modal__body">
                    <div className="modal__photos">
                        <div className="modal__photo modal__photo_1"></div>
                        <div className="modal__photo modal__photo_2"></div>
                        <div className="modal__photo modal__photo_3"></div>
                        <div className="modal__photo modal__photo_4"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCarInfo
