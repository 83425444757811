import React from 'react'
import cross from '../../static/img/cross.svg'

const ModalCarAdditional = ({close, approve}) => {

    return (
        <div onClick={() => close()}
             className="modal__wrapper modal__wrapper-photos modal-active">
            <div className="modal modal-photos" onClick={(e) => e.stopPropagation()}>
                <div className="modal__close" onClick={() => close()}>
                    <img src={cross} alt="cancel" width="16px" height="16px" />
                </div>
                <div className="modal__title modal__title-addition"><span>Требования к фотографиям</span></div>
                <div className="modal__body">
                    <p className="modal__text modal__text-addition">
                        1. Соответствие идентификационным номерам авто в реальности и по паспорту авто (тех. паспорт);
                    </p>
                    <p className="modal__text modal__text-addition">
                        2. Разрешение фотографий должно быть не менее 1920×1080 точек (пикселей);
                    </p>
                    <p className="modal__text modal__text-addition">
                        3. Фотографии должны быть не размытыми и с возможностью увеличения фотографии без потери качества минимум в 5 раз;
                    </p>
                    <p className="modal__text modal__text-addition">
                        4. Фотографии должны быть сделаны в дневное время;
                    </p>
                    <p className="modal__text modal__text-addition">
                        5. Авто не должен быть грязным;
                    </p>
                    <p className="modal__text modal__text-addition">
                        6. Исключены иные обстоятельства, которые могут препятствовать правильной оценке авто.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ModalCarAdditional
