import React from 'react'
import cross from '../../static/img/cross.svg'
import svidet1 from '../../static/img/svidet1.png'
import svidet2 from '../../static/img/svidet2.png'

const ModalCarInfo = ({close}) => {
    return (
        <div onClick={() => close()}
             className="modal__wrapper modal__wrapper-photos modal-active">
            <div className="modal modal-photos" onClick={(e) => e.stopPropagation()}>
                <div className="modal__close" onClick={() => close()}>
                    <img src={cross} alt="cancel" width="16px" height="16px"  />
                </div>
                <div className="modal__title visually-hidden"><span>Пример фотографий</span></div>
                <div className="modal__body">
                    <div className="modal__photos">
                        <img src={svidet1} style={{ width: '100%'}} />
                        <img src={svidet2} style={{ width: '100%'}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCarInfo
