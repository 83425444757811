import React, { createContext, useState } from 'react'
import Message from './Message'

export const Context = createContext({})

export default function BaseContextWrapper({ children }) {
    const [text, setText] = useState('')
    const [className, setClassName] = useState('')
    const [modalComponent, setModalComponent] = useState({})

    return (
        <Context.Provider value={{ setText, setClassName, setModalComponent, modalComponent }}>
            {children}

            {text ? (
                <Message
                    text={text}
                    className={className}
                    closeMessage={() => setText(null)} />
            ) : null}
        </Context.Provider>
    )
}
