import React from 'react'

export default function CarInfoForm({ response }) {
    return (
        <div className="main-info info">
            <p className="info__text info__desc">Страхователь:</p>
            <p className="info__text info__value">{
                `${response['holderLastname']} ${response['holderFirstname']}`
            }</p>
            <p className="info__text info__desc">№ полиса:</p>
            <p className="info__text info__value">{response['policyNumber']}</p>
        </div>
    )
}
