import React from 'react'
import logo  from '../../static/img/logo.svg'

const Header = ({header}) => (
    <header className="header">
        <div className="container header__container">
            <img
                src={logo}
                width="112px"
                height="54px"
                alt="Логотип BIMA Insurance"
                className="header__logo"
            />
            <h1 className="header-title">{header}</h1>
        </div>
    </header>
)

export default Header
