import React        from 'react'
import Main         from '../layouts/Main'
import CarInfoForm from "../components/CarInfoForm";
import CarUpdateForm from "../components/CarUpdateForm";
import {useLoad} from "../hooks/request";
import {carGetData} from "../urls";

export default function CarUpdate({ match }) {
    const { response } = useLoad({
        url:carGetData.replace('{code}', match.params.code)
    })

    if (!response)
        return (
            <div />
        )

    return (
        <Main header={"Обновление информации"}>
            <div className="main">
                <div className="container">
                    <CarInfoForm response={response} />
                    <CarUpdateForm id={response['policyNumber']} />
                </div>
            </div>
        </Main>
    )
}
