import React, {useEffect} from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import BaseContextWrapper from './components/common/BaseContext'
import { getEnv } from './utils/environment'
import CarUpdate from "./pages/CarUpdate";
import logo from './static/img/logo.svg'
import NotFound from "./pages/NotFound";


export default function App() {
    useEffect(() => {
        document.querySelector("link[rel='shortcut icon']").href = logo
        if (getEnv() === 'production') return
        document.title += ` ${getEnv().toUpperCase()}`
        
    }, [])

    return (
        <BaseContextWrapper>
            <BrowserRouter>
                <BaseContextWrapper>
                    <Switch>
                        <Route path="/:code" component={CarUpdate} exact />
                        <Route path="" component={NotFound} exact />
                    </Switch>
                </BaseContextWrapper>
            </BrowserRouter>
        </BaseContextWrapper>
    )
}
