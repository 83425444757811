import React, {useContext} from 'react'
import {Context} from "../../components/common/BaseContext";

const Modal = () => {
    const { modalComponent } = useContext(Context)

    return <>{modalComponent && modalComponent.content ? modalComponent.content : null}</>
}

export default Modal
