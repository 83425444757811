import React from 'react'
import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'

export default function Loader() {
    return (
      <div className="loader-wrapper">
          <span className="loader" />
      </div>
    )
}
